import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Box, Flex, Heading, Text } from "@chakra-ui/react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import CustomButtonBase from "@/components/CustomButtonBase"


const Overlay = () => (
  <Box
    position="absolute"
    width="full"
    height="full"
    backgroundColor="#FAFAFA"
  />
)

const onSurveyFormClick = href => {
    if (href) {
       window.open(href, "_self")
      }
  }


const PhoneImage = ({ image }) => (
  <Box
    as={GatsbyImage}
    image={getImage(image)}
    width={{ base: "304px", sm: "478px" }}
    height={{ base: "305px", sm: "478px" }}
    alt="user research page"
    pointerEvents="none"
  />
)

const query = graphql`
  {
    bannerPhonePreview: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "userresearch/banner/user-research-hero-image.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
      }
    }
  }
`

const Banner = () => {
  const { t } = useTranslation()
  const data = useStaticQuery(query)


  return (
    <Flex
      as="section"
      width="full"
      position="relative"
      alignItems="center"
    >
      <Overlay />
      <Flex
        position="relative"
        width="full"
        height="full"
        alignItems="center"
        justifyContent="center"
      >
        <Flex
          direction={{ base: "column-reverse", sm: "row" }}
          alignItems="center"
          mx="auto"
          maxW="6xl"
          px="6"
          width="full"
          height="full"
        >
          <Flex
            direction="column"
            width={{ base: "100%", sm: "65%" }}
            pb={{ base: "50", sm: "10" }}
            pr={{ base: "0", sm: "6" }}
            pt={{ base: "0", sm: "10" }}
          >
            <Heading
              fontWeight="600"
              textAlign={{ base: "initial", sm: "inherit" }}
              fontSize={{ base: "30px", md: "42px" }}
              lineHeight={{ base: "42px", md: "59px" }}
              color="#003686"
              dangerouslySetInnerHTML={{__html: t("home-page.banner-section.title"),}}
              />
            <Text color="rgba(15, 15, 15, 0.8)"
              fontWeight="400"
              textAlign={{ base: "initial", sm: "initial" }}
              fontSize={{ base: "18px", md: "18px" }}
              lineHeight={{ base: "29px", md: "29px" }}
              fontFamily="Noto Sans"
              py="6"
              dangerouslySetInnerHTML={{__html: t("home-page.banner-section.description"),}}
            />
            <CustomButtonBase
                text={t(`home-page.banner-section.cta`)}
                width={{ base: "108px", md: "108px" }}
                height={{ base: "56px", md: "56px" }}
                mx= "0"
                px={{ base: "2", md: "2" }}
                py={{ base: "2", md: "4" }}
                fontSize={{ base: "xs", sm: "md", md: "16px" }}
                fontWeight="700"
                lineHeight="16px"
                borderRadius="8px"
                backgroundColor="#0055B2"
                color="#FFFFFF"
                onClick={() => onSurveyFormClick('/userresearch/yara-farmer-expert-signup')}
              />
          </Flex>

          <Flex
            width={{ base: "100%", sm: "45%" }}
            justifyContent="center"
            marginLeft={{ base: "0px", sm: "80px" }}
          >
            <PhoneImage  image={data.bannerPhonePreview} />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Banner
